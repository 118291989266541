/* eslint-disable react/react-in-jsx-scope */
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  CardGroup,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import brain from '../../images/BRAIN.jpg';
import drCBrenner from '../../images/drCBrenner.jpg';
import studies from '../../images/eeg.jpg';
import students from '../../images/students.jpg';
import alumni from '../../images/therapy.jpg';
import contact from '../../images/contact.jpg';

const Main = () => {
  return (
    <Container fluid className="header-fix">
      <Row className="align-items-center mt-5 mb-5">
        <Col md="6" sm="12">
          <img
            alt="lots of ideas"
            src={brain}
            className="img-fluid mx-auto d-block rounded img-story imgCheck"
          />
        </Col>
        <Col md="6" sm="12">
          <h4 className="m-5 text-center">
            The mission of the Brain Potential Lab is to Help People Through Science. We are a
            psychology research group focused on the investigation of serious mental illness; namely
            schizophrenia and bipolar disorder. We use several different methods to study the
            experience and social consequences of having mental illness, and how these may be linked
            to the underlying neuropathology of the disorder. Through better understanding of the
            relationships between symptoms and biological functioning, we hope to inform treatment
            and improve the overall well-being of our patients.
          </h4>
          <div className="text-center">
            <img alt="Dr. Colleen Brenner, phD" src={drCBrenner} className="headShot rounded" />
          </div>
          <h5 className="text-center">Dr. Colleen Brenner, phD</h5>
        </Col>
      </Row>
      <Row className="align-items-center mt-5">
        <Col>
          <CardGroup>
            <Card className="p-3 studies">
              <CardImg alt="Current Studies" src={studies} className="rounded" />
              <CardBody className="text-center">
                <CardTitle tag="h4">Current Studies</CardTitle>
                <CardText>
                  Our studies are designed to seek a better understanding of the complex
                  relationships between life experience, neurobiology and physical health.
                </CardText>
                <Link to={'/current-studies'} className="btn btn-light">
                  Learn more
                </Link>
              </CardBody>
            </Card>
            <Card className="p-3 students">
              <CardImg alt="Current Students" src={students} className="rounded" />
              <CardBody className="text-center">
                <CardTitle tag="h4">Current Students</CardTitle>
                <CardText>
                  Each academic term The Lab is blessed with both new students and those working
                  their way through the program.
                </CardText>
                <Link to={'/current-students'} className="btn btn-light">
                  Learn more
                </Link>
              </CardBody>
            </Card>
            <Card className="p-3 alumni">
              <CardImg alt="Alumni" src={alumni} className="rounded" />
              <CardBody className="text-center">
                <CardTitle tag="h4">Alumni</CardTitle>
                <CardText>
                  Ready to venture off on their own, here's a listing of The Lab's former students.
                </CardText>
                <Link to={'/alumni'} className="btn btn-light">
                  Learn more
                </Link>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
      <Row className="align-items-center mt-5 mb-5">
        <Col md="6" sm="12">
          <img
            alt="Contact Us"
            src={contact}
            className="img-fluid mx-auto d-block rounded img-story mb-5"
          />
        </Col>
        <Col md="6" sm="12">
          <h2 className="text-center">Get Involved!</h2>
          <h4 className="text-center">We need your help!</h4>
          <Row>
            <Col className="text-center mr-5">
              <div>
                <a href="https://twitter.com/DrCBrenner" target="_blank" rel="noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    fill="currentColor"
                    className="bi bi-twitter m-5"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/people/Colleen-Brenner/100015856448886/?lst=100015856448886%3A100015856448886%3A1490115689"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    fill="currentColor"
                    className="bi bi-facebook m-5"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg>
                </a>
              </div>
            </Col>
          </Row>
          <p className="m-2 text-center">
            Friend us on Facebook! Tweet @ us on Twitter! If you'd like to participate in one of our
            studies, we're always looking test subjects.
          </p>
          <p className="m-2 text-center">
            <Link to={'/contact'} className="btn btn-dark">
              Contact Us
            </Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
