/* eslint-disable react/react-in-jsx-scope */
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';

import contact from '../../images/contact.jpg';

const Main = () => {
  return (
    <Container fluid className="header-fix">
      <Row className="align-items-center justify-content-center mt-3">
        <Col sm="11">
          <h1 className="text-center">CURRENT STUDIES</h1>
          <Card className="m-2 p-2">
            <CardBody>
              <CardTitle tag="h4">Brain Potential Wellness Study</CardTitle>
              <CardText>
                This study focuses on the relationships between physical &amp; mental health in
                individuals with psychosis. The best part is that this study involves FREE
                INDIVIDUAL THERAPY for those with psychosis! Research shows that individuals with
                psychosis have higher rates of metabolic syndrome and cardiovascular disease
                compared to the general population. The relationships between these physical health
                concerns, brain functioning, and symptoms is not well understood. Therefore, the
                aims of this study are to investigate whether there is a link between physical
                health, brain wave activity and cognition, and whether these relationships change as
                psychiatric symptoms improve. We will investigate these variables both before and
                after 12 weeks of individual cognitive behavioral therapy for psychosis. This
                treatment will be provided free of charge for qualifying individuals.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <CardTitle tag="h4">Auditory Entrainment in Mild Cognitive Impairment</CardTitle>
              <CardText>
                Older adults who experience mild cognitive impairments are at a higher risk for
                developing dementia. While there are currently no behavioral treatments for adults
                with dementia, animal studies have shown promising results using auditory
                entrainment stimuli to reverse some of the symptoms of dementia. Auditory
                entrainment is a series of clicks at a certain frequency. Listening to these sounds
                encourages synchronized neural activity at the same frequency as the clicks. We are
                investigating whether adults with mild cognitive impairment show improvement in
                cognitive functioning and blood-derived risk factors for dementia (amyloid &amp; tau
                proteins, 27 OHC and RTKN2) after listening to the auditory entrainment stimuli.
                This 6-month long study will determine whether auditory entrainment in the 40Hz
                condition results in improved brain wave activity, cognition, and risk factor
                variables over time,
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <CardTitle tag="h4">
                <a
                  href="https://thebrainpotentiallab.com/Supporting_The_Supporters_Flyer.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Supporting the Supporters: Telehealth Intervention for Family Caregivers of
                  Individuals with Psychosis
                </a>
              </CardTitle>
              <CardText>
                Individuals with psychosis often rely on family/informal caregivers to help with
                everyday activities, to take medication, attend appointments and for support when
                experiencing acute symptoms. As such, family caregivers often report higher rates of
                caregiver burden, depression, anxiety and lower quality of life. The program
                Powerful Tools for Caregivers is an empirically supported six-week class designed to
                provide support, coping skills, and resources for family caregivers. We have adapted
                this program to suit the specific needs of caregivers for those with psychosis, and
                will investigate it’s effectiveness in improving coping mechanisms, resilience,
                well-being, burden and self-stigma.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <CardTitle tag="h4">Metabolic Syndrome and Preventative Care in Psychosis</CardTitle>
              <CardText>
                Individuals with psychosis experiences higher rates of metabolic syndrome and
                cardiovascular disease compared to the general population. However, it is currently
                unclear whether the differences in prevalence rates we see between race and gender
                in the general population are also found in those with psychosis. Furthermore, we
                are interested in determining whether there are potential treatment and preventative
                healthcare differences between those with psychosis, those with another psychiatric
                diagnosis (i.e. anxiety &amp; depression) and those without a psychiatric diagnosis.
                Therefore, this study will investigate prevalence rates of metabolic and
                cardiovascular disease by mental health diagnosis, race &amp; gender within the Loma
                Linda Healthcare system. We will also investigate whether the components of
                metabolic syndrome &amp; cardiovascular disease are treated differently by mental
                health diagnosis, race &amp; gender and whether there are differences in
                preventative healthcare screenings (i.e. referrals to smoking cessation programs,
                pap smears, mammograms, prostate screenings) along these dimensions.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <CardTitle tag="h4">
                Metabolic Predictors of Inflammation Over Time in Schizophrenia
              </CardTitle>
              <CardText>
                Patients with schizophrenia are at increased risk of developing cardiovascular
                disease due to metabolic dysfunction. Mounting evidence suggests that metabolic risk
                factors are associated with chronic states of inflammation. Prolonged inflammatory
                states result in a wide range of impairments in domains of cognition, emotion, and
                metabolic processes. This study aimed to further understand the relationship the
                relationship between metabolic risk factors and their influence on inflammation over
                time in patients with schizophrenia. Results suggested higher rates of elevated
                inflammation measures in patients with schizophrenia. Each year after the start of
                the study was associated with decreased inflammation. However, in female patients
                with schizophrenia inflammation increased with age at encounter. Increases in
                glucose measures was associated with increased inflammation. Interaction effects
                found this relationship to be strongest in female patients with schizophrenia.
                Increases in BMI was associated with less inflammation in this sample of patients
                with schizophrenia. Increases in age significantly predicted inflammation across
                patients. These results provide further information on the relationship between
                metabolic dysfunction and inflammation.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <CardTitle tag="h4">
                Meta-Analysis of the Effect of Raloxifene on Cognition in Psychosis
              </CardTitle>
              <CardText>
                Raloxifene belongs to a class of drugs known as selective estrogen receptor
                modulators. It acts like an estrogen in some parts of the body, but as an estrogen
                blocker in other parts of the body. Raloxifene is typically prescribed to
                post-menopausal women to treat osteoporosis, however some studies have shown that
                improves cognitive performance in post-menopausal women with psychosis. Studies of
                the impact of Raloxifene are somewhat inconsistent and vary by menopausal status.
                Our lab is currently conducting a meta-analysis of the current literature on the
                impact of raloxifene on cognition in psychosis to clarify the various findings
                reported in the literature.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <CardTitle tag="h4">The Sound Bath: Facts and Frictions</CardTitle>
              <CardText>
                Sound bath is a popular form of sound healing therapy where instruments provide
                auditory stimuli within a range of different frequencies. It is thought that the
                benefits of the sound bath arise from the body’s tendency to entrain to external
                stimuli. There are a range of instruments that are used to provide sound bath
                therapy including singing bowls, gongs, Hang drums, bells whistles, cymbals,
                digeridoos, tuning forks and tanpura. Claims about the therapeutic effects of sound
                healing include pain reduction, stress-reduction, relaxation, emotional well-being,
                and improved cardiovascular health. This review paper will present a summary of the
                empirical findings associated with sound bath healing on psychological and physical
                health outcomes. It will also provide a roadmap for how to further our understanding
                of the psychophysiological impact of sound healing therapies.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <CardTitle tag="h4">
                The Neurobiological Effects of the Community Resiliency Model
              </CardTitle>
              <CardText>
                This project will investigate the neurobiological effects of the Community
                Resiliency Model (CRM) by conducting an electroencephalography (EEG) study in a
                sample of graduate students with anxiety. Anxiety and stress activate somatic
                systems in similar ways and are necessary to respond very quickly to events
                requiring an immediate response. CRM is a non-therapeutic model consisting of six
                wellness skills taken from the therapeutic Trauma Resiliency Model (TRM). It is an
                intervention that can help to regulate autonomic systems and aid proper functioning
                even in the moment of acute distress. Previous studies on CRM suggest improvements
                in wellbeing and a decline for indicators of stress, however, there have been no
                studies investigating the neurobiological effects of CRM. We will sample graduate
                students from Loma Linda University and randomly assign them to a treatment group or
                control group. We will record electroencephalography (EEG) at baseline, while
                students are in an anxious state, and while they are using CRM skills. We anticipate
                that the use of CRM skills will increase left frontal alpha asymmetry, and increase
                both alpha and theta power. This study will be the first to investigate the
                psychophysiological impact of CRM, and could provide physiological evidence for
                somatic based interventions.
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="align-items-center mt-5 mb-5">
        <Col md="6" sm="12">
          <img
            alt="Contact Us"
            src={contact}
            className="img-fluid mx-auto d-block rounded img-story"
          />
        </Col>
        <Col md="6" sm="12">
          <h2 className="text-center">Get Involved!</h2>
          <h4 className="text-center">We need your help!</h4>
          <Row>
            <Col className="text-center mr-5">
              <div>
                <a href="https://twitter.com/DrCBrenner" target="_blank" rel="noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    fill="currentColor"
                    className="bi bi-twitter m-5"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/people/Colleen-Brenner/100015856448886/?lst=100015856448886%3A100015856448886%3A1490115689"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    fill="currentColor"
                    className="bi bi-facebook m-5"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg>
                </a>
              </div>
            </Col>
          </Row>
          <p className="m-2 text-center">
            Friend us on Facebook! Tweet @ us on Twitter! If you'd like to participate in one of our
            studies, we're always looking test subjects.
          </p>
          <p className="m-2 text-center">
            <Link to={'/contact'} className="btn btn-dark">
              Contact Us
            </Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
