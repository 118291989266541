/* eslint-disable react/react-in-jsx-scope */
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';

import drCBrenner from '../../images/drCBrenner.png';
import aChen from '../../images/aChen.png';
import eChristopherson from '../../images/eChristopherson.png';
import hDeMercy from '../../images/hDeMercy.png';
import dFranz from '../../images/dFranz.png';
import cRamorino from '../../images/cRamorino.png';
import bTessler from '../../images/bTessler.png';

const Main = () => {
  return (
    <Container fluid className="header-fix">
      <Row className="align-items-center justify-content-center mt-3">
        <Col sm="11">
          <h1 className="text-center">STUDENTS</h1>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Dr Colleen Brenner, PhD" src={drCBrenner} className="headShot rounded" />
              <CardTitle tag="h4">Dr Colleen Brenner, PhD</CardTitle>
              <CardText>
                Colleen received her bachelor’s degree in psychology from the University of
                California, San Diego, and her Ph.D. in Clinical Psychology at Indiana University,
                Bloomington. She completed her clinical internship at the Palo Alto Veterans
                Hospital and her postdoctoral work at the Indiana University, Purdue University
                Indianapolis, School of Medicine. She was a faculty member in the Department of
                Psychology at the University of British Columbia from 2008-2015, and is now an
                Associate Professor at Loma Linda University. She has over 20 years of experience in
                the field of serious mental illness, and over 10 years of experience teaching
                psychology at the undergraduate and graduate levels. Colleen is passionate about
                student mentorship and tries to integrate quality training opportunities into her
                clinical supervisory and research advisor roles.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Ashley Chen" src={aChen} className="headShot rounded" />
              <CardTitle tag="h4">Ashley Chen</CardTitle>
              <CardText>
                Ashley is a first year Ph.D. student. She graduated from University of California,
                Irvine in 2021 with a B.A. in Psychology as well as Music. Her research interests
                include examining potential differences in electroencephalography (EEG) activity in
                individuals with or without metabolic syndrome, as well as in individuals with
                schizophrenia and neurologically healthy individuals.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Eric Christopherson" src={eChristopherson} className="headShot rounded" />
              <CardTitle tag="h4">Eric Christopherson</CardTitle>
              <CardText>
                Eric Christopherson graduated from The San Francisco Conservatory of music with a
                B.M. and M.M. in percussion performance where he was recognized for his
                non-traditional pedagogical contributions to the art of percussion. In addition to
                playing the marimba, Mr. Christopherson has developed an expansive range of
                interests, including business development, woodworking, billiards, writing, improv
                theater, mindfulness training, theology, and philosophy.  Furthermore, Mr.
                Christopherson was a semi-finalist in the 2017 Toastmasters World Championship of
                Public Speaking, where he performed his speech “October Bloom,” which explored human
                resiliency and post-traumatic growth themes. Today, Mr. Christopherson is a
                third-year graduate student in clinical psychology at Loma Linda University. Mr.
                Christopherson is honored to provide psychotherapy and neurological assessment to
                diverse populations, such as the local Native American tribal population and
                American Veterans through the U.S. Veterans Administration. Eric’s research
                concentrations include sound healing, primary care psychology, health psychology,
                religion, and spirituality. Furthermore, Mr. Christopherson is an accomplished Hang
                Drum player and leads live music meditation and sound baths sessions.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Haylie DeMarcy" src={hDeMercy} className="headShot rounded" />
              <CardTitle tag="h4">Haylie DeMarcy</CardTitle>
              <CardText>
                Haylie is a second year Ph.D. candidate in Clinical Psychology. She received her
                B.A. in psychology from Utah State University. Prior to starting graduate school,
                she worked with families of children with developmental disorders and assessed
                parent-child interactions for indicators of interaction quality. This enabled her to
                examine the use of attachment and responsive parenting practices and their
                correlation with increased emotional regulation and secure attachment throughout
                childhood, as well as how early development in children of mothers with high
                psychological risk are influenced by Early Head Start. Her research interests
                include investigating therapeutic interventions for cognitive impairment in health
                aging individuals and in clinical populations with neurodegenerative disorders.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Daniel Franz" src={dFranz} className="headShot rounded" />
              <CardTitle tag="h4">Daniel Franz</CardTitle>
              <CardText>
                Daniel is a 5 th  year Ph.D. student in the clinical psychology doctoral program at
                Loma Linda University. His research is focused on understanding metabolic
                dysfunction, chronic inflammation, and how this information may be used to help
                guide treatment in patients with schizophrenia. His clinical interests involve
                serving underserved populations in forms of therapy and neuropsychological
                assessment. Daniel is an adjunct professor at La Sierra University and plans to
                pursue a career in academia. Daniel and his wife Paige enjoy running together and
                met one another while they were on the cross-country team at UC San Diego.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Carina Ramorino" src={cRamorino} className="headShot rounded" />
              <CardTitle tag="h4">Carina Ramorino</CardTitle>
              <CardText>
                Carina is a first-year PsyD student with clinical interests in the assessment and
                treatment of severe and persistent mental illnesses within inpatient and residential
                settings. She is from San Francisco and completed her undergraduate degree at
                Macalester College.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Barak Tessler" src={bTessler} className="headShot rounded" />
              <CardTitle tag="h4">Barak Tessler</CardTitle>
              <CardText>
                Barak is a 3 rd year Ph.D. student in the Clinical Psychology program at Loma Linda
                University. His clinical focus is currently on the family caregivers of individuals
                with psychosis. He aims to develop a holistic approach that focuses on social
                factors such as stigma, economic barriers, and psychoeducation on available
                resources that family caregivers and individuals with psychosis require when
                attempting to navigate society while managing the illness.
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
