/* eslint-disable react/react-in-jsx-scope */
import { Container, Row, Col } from 'reactstrap';

import ContactForm from '../ContactForm/ContactForm';

const Main = () => {
  return (
    <Container fluid className="header-fix">
      <Row className="align-items-center mt-3">
        <Col>
          <h1 className="text-center">CONTACT</h1>
          <h5 className="text-center m-5">
            Thanks so much for wanting to get in touch with The Lab, we look forward to hearing from
            you, especially if you want to volunteer for one of our studies.
          </h5>
        </Col>
      </Row>
      <ContactForm />
    </Container>
  );
};

export default Main;
