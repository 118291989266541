import { Routes, Route } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import Main from './components/Main/Main';
import Studies from './components/Studies/Studies';
import Students from './components/Students/Students';
import Alumni from './components/Alumni/Alumni';
import Contact from './components/Contact/Contact';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Main />} />
          <Route path="current-studies" element={<Studies />} />
          <Route path="current-students" element={<Students />} />
          <Route path="alumni" element={<Alumni />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<Main />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
