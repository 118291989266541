/* eslint-disable react/react-in-jsx-scope */
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';

import sGehring from '../../images/sGehring.png';
import sHerman from '../../images/sHerman.png';
import kIgirio from '../../images/kIgirio.png';
import yKolahi from '../../images/yKolahi.png';
import cLaw from '../../images/cLaw.png';
import bMoore from '../../images/bMoore.png';
import aNguyen from '../../images/aNguyen.png';
import kSanders from '../../images/kSanders.png';
import mSimpson from '../../images/mSimpson.png';

const Main = () => {
  return (
    <Container fluid className="header-fix">
      <Row className="align-items-center justify-content-center mt-3">
        <Col sm="11">
          <h1 className="text-center">ALUMNI</h1>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Spring Flores Gehring, MA" src={sGehring} className="headShot rounded" />
              <CardTitle tag="h4">Spring Flores Gehring, MA</CardTitle>
              <CardText>
                Spring was a Ph.D student with a clinical focus in neuropsychology and a goal to be
                a boarded clinical neuropsychologist that provides assessment and cognitive
                rehabilitation. She received a BA in psychology with a minor in English, and spent 8
                years as a certified public high school educator teaching classes in English,
                psychology, and sociology, and was honored as a Coca-Cola Educator of Distinction.
                Spring’s research interests include cognitive decline due to serious mental illness,
                traumatic brain injury, stroke, or dementia, and rehabilitation of cognitive
                abilities after neurological insult. Currently she is an extern at Loma Linda
                Medical Center East Campus providing assessment, rehabilitation, and brief
                psychotherapy for an inpatient and outpatient population. In addition, Spring
                provides educational community presentations on epilepsy development, recognition,
                and first-aid through the Epilepsy Support Network of Orange County.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Shaina Herman" src={sHerman} className="headShot rounded" />
              <CardTitle tag="h4">Shaina Herman</CardTitle>
              <CardText>
                Shaina was a Psychology PhD student and her clinical interests were to work with
                patients suffering from serious and persistent mental illnesses. her research
                directions included effects of therapeutic intervention and treatment outcomes of
                medication and psychotherapy for patients with serious mental illness.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Kimberly Igirio" src={kIgirio} className="headShot rounded" />
              <CardTitle tag="h4">Kimberly Igirio</CardTitle>
              <CardText>
                Kimberly was a PhD student with great interest in abnormal psychology, especially
                psychotic disorders and personality disorders. Some of her main goals as a graduate
                researcher were to study the relationship that cannabis use may have in inducing
                psychosis and to seek relationships between substance abuse and exacerbation of
                psychotic/personality disorders.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Yasmine Kolahi" src={yKolahi} className="headShot rounded" />
              <CardTitle tag="h4">Yasmine Kolahi</CardTitle>
              <CardText>
                Yasmine graduated with a B.A. in Psychology from University of California, Berkeley.
                She was a student at Loma Linda University with a Neuropsychology concentration. Her
                research focused on the relationship between symptom severity of patients with
                schizophrenia, resting EEG activity, and cognition.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Chris Law" src={cLaw} className="headShot rounded" />
              <CardTitle tag="h4">Chris Law</CardTitle>
              <CardText>
                Chris was a graduate student in the Psy.D program. He was interested in forensics
                psychology, which expands into areas like human trafficking and severe mental
                illness. In the future he hopes to open a non-profit organization that helps
                victims, both foreign and domestic, of human trafficking.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Britan Moore" src={bMoore} className="headShot rounded" />
              <CardTitle tag="h4">Britan Moore</CardTitle>
              <CardText>
                Britan was a PhD student. Her research interests included examining the differences
                in presentations of psychosis across diagnoses and the neurodevelopment of
                Antisocial Personality Disorder and Conduct Disorder.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Ann Nguyen" src={aNguyen} className="headShot rounded" />
              <CardTitle tag="h4">Ann Nguyen</CardTitle>
              <CardText>
                Ann was a Ph.D. clinical psychology graduate student. Her primary interest was in
                the neurophysiological substrates underlying the sensory and cognitive disturbances
                observed in individuals with schizophrenia. She is particularly interested in how
                deficits in the processing of sensory information can lead to the phenomenon of
                sensory overload and changes in aspects of cognition, all to which contribute to the
                clinical symptoms experienced by individuals with schizophrenia. Her current
                research utilizes electroencephalography (EEG) to elucidate the biological
                mechanisms of sensory gating—the ability to attend to novel and salient stimuli in
                addition to filtering out redundant and irrelevant sensory information—in
                individuals with schizophrenia and neurologically healthy individuals.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Keshia Sanders" src={kSanders} className="headShot rounded" />
              <CardTitle tag="h4">Keshia Sanders</CardTitle>
              <CardText>
                Keshia Sanders was a PhD student interested in serious mental illness and her
                research examined demographic differences in resting state EEG and then comparing
                healthy controls with patients with schizophrenia. Her clinical interests included
                Forensic and Neuropsychology. She had practicum at Patton State Hospital and the
                Indian Health Clinics for some of her practicum experience. Other practicum sites
                included the Loma Linda VA and Casa Colina. Her long term goal is to work either in
                a State Hospital or the VA system.
              </CardText>
            </CardBody>
          </Card>
          <Card className="m-2 p-2">
            <CardBody>
              <img alt="Michelle Simpson" src={mSimpson} className="headShot rounded" />
              <CardTitle tag="h4">Michelle Simpson</CardTitle>
              <CardText>
                Michelle Simpson was a graduate student in the Psy.D. program. She is from Highland,
                CA and completed her undergraduate degree at San Jose State University. Her clinical
                interests were family support/acceptance in individuals with severe mental illness
                and treatment outcomes.
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
